import vrt from '../assets/img/game/9/d9.jpg'
import kuhinja from '../assets/img/game/9/k9.jpg'
import vrataVrt from '../assets/img/game/vrt-vrata.png'
import outItem1 from '../assets/img/game/9/vrt/1.png'
import outItem2 from '../assets/img/game/9/vrt/2.png'
import outItem3 from '../assets/img/game/9/vrt/3.png'
import outItem4 from '../assets/img/game/9/vrt/4.png'
import outItem5 from '../assets/img/game/9/vrt/5.png'
import p1 from '../assets/img/game/9/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/9/kuhinja/creative-puding-mint-cokolada.jpg'
import k2 from '../assets/img/game/9/kuhinja/Aroma_Naranca_2411 (1).png'
import k3 from '../assets/img/game/9/kuhinja/pladanj.png'
import k4 from '../assets/img/game/9/kuhinja/Modlice Zečja obitelj-6.png'
import k5 from '../assets/img/game/9/kuhinja/recept13.png'
import vrataKuhinja from '../assets/img/game/kuhinja-vrata.png'

const day_9 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-91deg", pitch: "-39.7deg" },
                size: { width: 18, height: 23 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "87.5deg", pitch: "-29.9deg" },
                size: { width: 20, height: 29 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-32.8deg", pitch: "-27deg" },
                size: { width: 19, height: 22 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "198deg", pitch: "-3.9deg" },
                size: { width: 20, height: 26 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-120.2deg", pitch: "-16deg" },
                size: { width: 17, height: 20 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-53.2deg", pitch: "-6.5deg" },
                size: { width: 58, height: 95 },
                product_title: 'Dr. Oetker Creative pudding s okusom čokolade i mente',
                product_description: 'Kada poželite puding s ovježavajućim okusom onda je Dr. Oetker Creative puding s okusom čokolade i mente savršen odabir za vas.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/creative-pudding/creative-pudding-cokolada-menta'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-34.2deg", pitch: "-8.9deg" },
                size: { width: 26, height: 83 },
                product_title: 'Tekuća aroma za hranu – naranča',
                product_description: 'Dr. Oetker prirodna aroma naranče idealna je za aromatiziranje vaših omiljenih deserata kako bi dobili osvježavajuću, citrusnu notu.',
                product_image: k2,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/arome/tekuca-aroma-naranca'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "7.2deg", pitch: "-41.3deg" },
                size: { width: 200, height: 200 },
                product_title: 'Pladanj za kolače rotirajući',
                product_description: 'Rotirajući pladanj za kolače je vrlo korisno pomagalo pri punjenju i ukrašavanju kolača. Od sada na još lakši i praktičniji način možete napraviti slojevite kolače!',
                product_image: k3,
                product_url: 'https://oetker-shop.hr/proizvod/pladanj-za-kolace-rotirajuci/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-28.2deg", pitch: "-39.3deg" },
                size: { width: 150, height: 150 },
                product_title: 'Modlice za kekse Zečja obitelj – 3 kom',
                product_description: 'Ovog Uskrsa pomoću modlica “Zečja obitelj” napravite najkreativnije keksiće za obitelj i prijatelje! Keksiće možete izrezati modlicama u tri različite veličine i ukrasiti ih po želji.',
                product_image: k4,
                product_url: 'https://oetker-shop.hr/proizvod/modlice-za-kekse-zecja-obitelj-3-kom/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "168.2deg", pitch: "-52.9deg" },
                size: { width: 225, height: 225 },
                product_title: 'Kolač iz kalupa za Uskrs',
                product_description: 'Pripremite ovaj svijetli kolač s tamnim motivom uskršnjeg zeca i uskršnjim jajima',
                product_image: k5,
                product_url: 'https://www.oetker.hr/hr-hr/recept/r/kolac-iz-kalupa-za-uskrs'
            }
        ]
    },
]

export default day_9