import vrt from '../assets/img/game/4/d4.jpg'
import kuhinja from '../assets/img/game/4/k4.jpg'
import outItem1 from '../assets/img/game/4/vrt/1.png'
import outItem2 from '../assets/img/game/4/vrt/2.png'
import outItem3 from '../assets/img/game/4/vrt/3.png'
import outItem4 from '../assets/img/game/4/vrt/4.png'
import outItem5 from '../assets/img/game/4/vrt/5.png'
import p1 from '../assets/img/game/4/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/4/kuhinja/Bourbon_Vanilin_HR.png'
import k2 from '../assets/img/game/4/kuhinja/Aroma_Naranca_2411 (1).png'
import k3 from '../assets/img/game/4/kuhinja/96896.png'
import k4 from '../assets/img/game/4/kuhinja/Torta u obliku zeca.png'
import k5 from '../assets/img/game/4/kuhinja/alupi za puding s poklopcem.png'
import inItem1 from '../assets/img/game/4/kuhinja/old/aroma-naranca.png'

const day_4 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-50.8deg", pitch: "-6.2deg" },
                size: { width: 15, height: 20 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "28.5deg", pitch: "-26deg" },
                size: { width: 23, height: 25 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-16.8deg", pitch: "-22deg" },
                size: { width: 20, height: 25 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "68deg", pitch: "-38.3deg" },
                size: { width: 20, height: 25 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "53deg", pitch: "-6.8deg" },
                size: { width: 19, height: 24 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "149.2deg", pitch: "-52.2deg" },
                size: { width: 35, height: 94 },
                product_title: 'Bourbon vanilin šećer',
                product_description: 'Dr. Oetker Bourbon vanilin šećer daje nježni miris i fini okus prave bourbon vanilije šlagovima, tijestima i nadjevima za kolače.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/vanilin-secer/bourbon-vanilija-secer'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "15deg", pitch: "-19.1deg" },
                size: { width: 26, height: 54 },
                product_title: 'Tekuća aroma za hranu – naranča',
                product_description: 'Dr. Oetker prirodna aroma naranče idealna je za aromatiziranje vaših omiljenih deserata kako bi dobili osvježavajuću, citrusnu notu.',
                product_image: k2,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/arome/tekuca-aroma-naranca'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "235.2deg", pitch: "-24.5deg" },
                size: { width: 29, height: 63 },
                product_title: 'Pasta od bourbon vanilije',
                product_description: 'Dr. Oetker Pasta od vanilije sadrži sjemenke madagaskarske vanilije najviše kvalitete i koristi se za aromatiziranje kolača i deserata, a posebno je ukusna u sladoledu, panna cotti, šlagu i palačinkama.',
                product_image: k3,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/bourbon-vanilija/pasta-od-bourbon-vanlije'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-13.8deg", pitch: "-41.5deg" },
                size: { width: 126, height: 170 },
                product_title: 'Torta u obliku zeca',
                product_description: 'Efektna, razigrana i ukusna torta - idealna za obiteljsko okupljanje ili kao poklon za Uskrs',
                product_image: k4,
                product_url: 'https://www.oetker.hr/recepti/r/torta-u-obliku-zeca'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-40.8deg", pitch: "-34deg" },
                size: { width: 142, height: 100 },
                product_title: 'Kalupi za puding s poklopcem 6×150 ml',
                product_description: 'Set od 6 malih kalupa idealan je za serviranje Dr. Oetker pudinga i ostalih slastica. Kalupi nježnih pastelnih boja (ružičaste, svijetlo zelene i svijetlo plave) savršeno će se uklopiti u svaku kuhinju. Izrađeni su od visokokvalitetne plastike, promjera oko 7,5 cm i kapaciteta do 150 ml. Veliki poklopac služi za zaštitu aroma, a mali za otpuštanje vakuuma i lakše odvajanje od kalupa prilikom posluživanja.',
                product_image: k5,
                product_url: 'https://oetker-shop.hr/proizvod/kalupi-za-puding-s-poklopcem-6x150-ml/'
            }
        ]
    },
]

export default day_4