import vrt from '../assets/img/game/11/d11.jpg'
import kuhinja from '../assets/img/game/11/k11.jpg'
import outItem1 from '../assets/img/game/11/vrt/1.png'
import outItem2 from '../assets/img/game/11/vrt/2.png'
import outItem3 from '../assets/img/game/11/vrt/3.png'
import outItem4 from '../assets/img/game/11/vrt/4.png'
import outItem5 from '../assets/img/game/11/vrt/5.png'
import p1 from '../assets/img/game/11/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/11/kuhinja/3D_MALI_Seceri_CIMET.png'
import k2 from '../assets/img/game/11/kuhinja/drOetker-Finesse-limun-1200x1200.png'
import k3 from '../assets/img/game/11/kuhinja/Posuda za topljenje čokolade-1.png'
import k4 from '../assets/img/game/11/kuhinja/170000817-Modeli-za-saumrole-4kom-1.png'
import k5 from '../assets/img/game/11/kuhinja/recept8.png'

const day_11 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "14.8deg", pitch: "-31.8deg" },
                size: { width: 24, height: 31 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-78deg", pitch: "-42.5deg" },
                size: { width: 26, height: 29 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "54.3deg", pitch: "-8.5deg" },
                size: { width: 24, height: 26 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "123.9deg", pitch: "-48deg" },
                size: { width: 26, height: 31 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-128deg", pitch: "-22.5deg" },
                size: { width: 26, height: 30 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "155.8deg", pitch: "-46.2deg" },
                size: { width: 60, height: 122 },
                product_title: 'Cimet šećer',
                product_description: 'Dr. Oetker Cimet šećer savršen je dodatak za sve slastice koji će vam ispuniti dom finim mirisom cimeta i svježe pripremljenih kolača.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/aroma-seceri/cimet-secer'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "242.2deg", pitch: "-29deg" },
                size: { width: 65, height: 90 },
                product_title: 'Finesse naribana korica limuna',
                product_description: 'Dr. Oetker Finesse proizvodi se iz prirodne korice limuna i atraktivna je zamjena za uporabu ribane korice limunove voćke u receptima.',
                product_image: k2,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/arome/finesse-naribana-korica-limuna'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "7.7deg", pitch: "-36.3deg" },
                size: { width: 170, height: 120 },
                product_title: 'Posuda za topljenje čokolade, nehrđajući čelik',
                product_description: 'Posuda za topljenje čokolade izrađena je od visokokvalitetnog nehrđajućeg čelika s posebno dugačkom ružkom za lakše rukovanje. Ovo kuhinjsko pomagalo idealno je za otapanje čokolade i kuverture.',
                product_image: k3,
                product_url: 'https://oetker-shop.hr/proizvod/posuda-za-topljenje-cokolade-nehrdajuci-celik/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "295.2deg", pitch: "-30.5deg" },
                size: { width: 130, height: 140 },
                product_title: 'Modeli za šaumrole, 4kom',
                product_description: 'Zahvaljujući protivljepljivoj površini, šaumrole od lisnatog tijesta se lako odvajaju od modela i mogu se puniti i ukrašavati prema vlastitim željama: bilo kremom, šlagom, svježim sirom, raguom ili francuskom salatom',
                product_image: k4,
                product_url: 'https://oetker-shop.hr/proizvod/modeli-za-saumrole-4kom/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "262.2deg", pitch: "-28.7deg" },
                size: { width: 190, height: 210 },
                product_title: 'Torta u obliku zeca od fondanta',
                product_description: 'Iskušajte se u dekoriranju slatke torte u obliku zeca',
                product_image: k5,
                product_url: 'https://www.oetker.hr/hr-hr/recept/r/torta-u-obliku-zeca-od-fondanta'
            },
        ]
    },
]

export default day_11