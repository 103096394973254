import vrt from '../assets/img/game/1/dan1.jpg'
import kuhinja from '../assets/img/game/1/dan1a.jpg'
import vrataVrt from '../assets/img/game/vrt-vrata.png'
import outItem1 from '../assets/img/game/1/vrt/p1.png'
import outItem2 from '../assets/img/game/1/vrt/p2.png'
import outItem3 from '../assets/img/game/1/vrt/p3.png'
import outItem4 from '../assets/img/game/1/vrt/p4.png'
import outItem5 from '../assets/img/game/1/vrt/p5.png'
import p1 from '../assets/img/game/1/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/1/kuhinja/rum.jpg'
import k2 from '../assets/img/game/1/kuhinja/5997381343664-drOetker-Gustin-200g-1.png'
import k3 from '../assets/img/game/1/kuhinja/creative-puding-vanilija-kardamom@2x.webp'
import k4 from '../assets/img/game/1/kuhinja/4044935018705-Zeko kalup na zatvaranje-4.png'
import k5 from '../assets/img/game/1/kuhinja/recept1.png'
import vrataKuhinja from '../assets/img/game/kuhinja-vrata.png'

const day_1 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-23.5deg", pitch: "-24.1deg" },
                size: { width: 24, height: 29 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "46deg", pitch: "-12deg" },
                size: { width: 16, height: 23 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "138.9deg", pitch: "-52.8deg" },
                size: { width: 29, height: 22 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "234.6deg", pitch: "-20deg" },
                size: { width: 20, height: 29 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-121deg", pitch: "-46.5deg" },
                size: { width: 18, height: 24 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "352.2deg", pitch: "-13.8deg" },
                size: { width: 28, height: 45 },
                product_title: 'Rum šećer',
                product_description: 'Dr. Oetker Rum šećer savršena je opcija za aromatiziranje raznih vrsta kolača, slastica i napitaka bez potrebe za dodavanjem alkoholnog pića.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/aroma-seceri/rum-secer'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "251.2deg", pitch: "-27.5deg" },
                size: { width: 105, height: 132 },
                product_title: 'Gustin fini jestivi škrob',
                product_description: 'Koristite Dr. Oetker Gustin fini jestivi škrob kako bi vaše tijesto bilo sočnije, a krem juhe i umaci još gušći.',
                product_image: k2,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/gustin/gustin-fini-jestivi-srkob'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-22deg", pitch: "-35.3deg" },
                size: { width: 60, height: 110 },
                product_title: 'Creative puding vanilija kardamom',
                product_description: 'Pripremite se za neočekivani spoj dva uzbudljiva okusa – vanilija i kardamom! Creative pudinzi osmišljeni su jedinstvenom kombinacijom dvaju suprotnih okusa koji će vas očarati svježinom uz dobro poznatu teksturu pudinga.',
                product_image: k3,
                product_url: 'https://oetker-shop.hr/proizvod/creative-puding-vanilija-kardamom/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "169.7deg", pitch: "-52deg" },
                size: { width: 214, height: 235 },
                product_title: 'Zeko kalup na zatvaranje, zlatni',
                product_description: 'Pravi klasik za Uskrs! Uz ovaj Dr. Oetker kalup zlatne boje ispecite kolač u obliku slatkog zečića, a neprianjajući premaz će vam omogućiti lako uklanjanje iz kalupa.',
                product_image: k4,
                product_url: 'https://oetker-shop.hr/proizvod/zeko-kalup-na-zatvaranje-zlatni/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "295.2deg", pitch: "-28.5deg" },
                size: { width: 110, height: 125 },
                product_title: 'Keksi sa sirom u obliku zeca',
                product_description: 'Brzo i jednostavno pripremite ove simpatične kekse u obliku zeca s ribanim parmezanom za uskrsni brunch',
                product_image: k5,
                product_url: 'https://www.oetker.hr/hr-hr/recept/r/keksi-sa-sirom-u-obliku-zeca'
            }
        ]
    },
]

export default day_1