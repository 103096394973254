import vrt from '../assets/img/game/10/d10.jpg'
import kuhinja from '../assets/img/game/10/k10.jpg'
import outItem1 from '../assets/img/game/10/vrt/1.png'
import outItem2 from '../assets/img/game/10/vrt/2.png'
import outItem3 from '../assets/img/game/10/vrt/3.png'
import outItem4 from '../assets/img/game/10/vrt/4.png'
import outItem5 from '../assets/img/game/10/vrt/5.png'
import p1 from '../assets/img/game/10/vrt/20204-Boje-za-jaja@2x.webp'
import k1 from '../assets/img/game/10/kuhinja/3D_MALI_Seceri_LIMUN.png'
import k2 from '../assets/img/game/10/kuhinja/5997381346917-drOetker-Kakao-za-kolace-1.png'
import k3 from '../assets/img/game/10/kuhinja/van.jpg'
import k4 from '../assets/img/game/10/kuhinja/kutija-nostalgija-2.png'
import k5 from '../assets/img/game/10/kuhinja/Uskrsna pletenica s 5 traka.png'

const day_10 = [
    {   main: [
            {
                id: 0,
                image: vrt
            },
            {
                id: 1,
                image: kuhinja
            }
        ],
        outside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "5deg", pitch: "-20.1deg" },
                size: { width: 280, height: 809 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "75deg", pitch: "-31.7deg" },
                size: { width: 24, height: 27 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "-21.8deg", pitch: "-20.5deg" },
                size: { width: 17, height: 23 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-78.8deg", pitch: "-16deg" },
                size: { width: 19, height: 23 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-146.5deg", pitch: "-26deg" },
                size: { width: 30, height: 36 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-69.2deg", pitch: "-35.5deg" },
                size: { width: 24, height: 28 },
                product_title: 'Boje za jaja',
                product_description: 'Pomoću Dr. Oetker boje za jaja napravite najljepše i najšarenije pisanice za vaš uskrsni stol ili košaru.',
                product_image: p1,
                product_url: 'https://www.oetker.hr/proizvoda/p/boje-za-jaja'
            },
        ],
        inside: [
            {
                id: 0,
                vrata: true,
                trigger_image: ' ',
                position: { yaw: "-310.2deg", pitch: "-25.1deg" },
                size: { width: 203, height: 650 },
                product_title: '',
                product_description: '',
                product_image: '',
                product_url: ''
            },
            {
                id: 1,
                trigger_image: ' ',
                position: { yaw: "-11deg", pitch: "-13.5deg" },
                size: { width: 45, height: 55 },
                product_title: 'Limun šećer',
                product_description: 'Dr. Oeker Limun zahvaljujući slatko-kiseloj kombinaciji vašim kolačima daje posebnu aromu.',
                product_image: k1,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/aroma-seceri/limun-secer'
            },
            {
                id: 2,
                trigger_image: ' ',
                position: { yaw: "16deg", pitch: "-18.5deg" },
                size: { width: 60, height: 85 },
                product_title: 'Kakao za kolače',
                product_description: 'Dr. Oetker Kakao za kolače svojom punoćom i intentizvnom tamnom bojom, savršen je dodatak čokoladnim tijestima i kremama za kolače kao i za dekoraciju.',
                product_image: k2,
                product_url: 'https://www.oetker.hr/hr-hr/nasi-proizvodi/dodaci-za-kolace/kakao-za-kolace/kakao-za-kolace'
            },
            {
                id: 3,
                trigger_image: ' ',
                position: { yaw: "-139.8deg", pitch: "-6deg" },
                size: { width: 75, height: 120 },
                product_title: 'Creative puding vanilija kardamom',
                product_description: 'Pripremite se za neočekivani spoj dva uzbudljiva okusa – vanilija i kardamom! Creative pudinzi osmišljeni su jedinstvenom kombinacijom dvaju suprotnih okusa koji će vas očarati svježinom uz dobro poznatu teksturu pudinga.',
                product_image: k3,
                product_url: 'https://oetker-shop.hr/proizvod/creative-puding-vanilija-kardamom/'
            },
            {
                id: 4,
                trigger_image: ' ',
                position: { yaw: "-126.8deg", pitch: "-42.5deg" },
                size: { width: 255, height: 200 },
                product_title: 'Kutija za kekse Nostalgija',
                product_description: 'Povijesni motiv iz 1890-ih čini ovu kutiju za kekse veoma posebnom. Kutija je raskošno ukrašena reljefom sa svih strana, uključujući i poklopac. Namjenjena je za čuvanje domaćih keksa, keksića, medenjaka i ostalih slatkih delicija, a u njoj također možete čuvati Dr. Oetker pudinge, dekore, slatke obroke, dodatke za kolače ili osobne predmete.',
                product_image: k4,
                product_url: 'https://oetker-shop.hr/proizvod/kutija-za-kekse-nostalgija/'
            },
            {
                id: 5,
                trigger_image: ' ',
                position: { yaw: "-37.2deg", pitch: "-36.5deg" },
                size: { width: 285, height: 150 },
                product_title: 'Uskrsna pletenica s 5 traka',
                product_description: 'Klasična pletenica s 5 traka sa slikovnim uputama, vrlo ukusna, s dodatkom komadića čokolade za uskrsni doručak ili za svaki dan',
                product_image: k5,
                product_url: 'https://www.oetker.hr/recepti/r/uskrsna-pletenica-s-5-traka'
            }
        ]
    },
]

export default day_10